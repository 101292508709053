/**
* CSS file to hold styles that apply across the application
*/

html,
body {
  font-family: "Monospace", monospace;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
}

div#main {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

section {
  position: relative;
  width: 100vw;
}

section.min-height {
  min-height: 50vh;
}

section.padding {
  padding: 0;
}

section img.background {
  height: 100%;
  left: 50%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100vmax;
  z-index: -1;
}

h1 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 1;
  margin: 1rem;
  color: #ffffff;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
  margin: 1rem;
  color: #ffffff;
}

h3 {
  font-weight: 200;
  font-size: 1rem;
  margin: 1rem;
  color: #ffffff;
}

p {
  font-size: 1rem;
  line-height: 1.25;
  margin: 1rem;
  font-weight: 300;
  color: #ffffff;
}

p.large {
  font-size: 2rem;
  color: #ffffff;
}

p.small {
  font-size: 0.5rem;
  color: #ffffff;
}

hr {
  margin: 1rem;
}

li {
  line-height: 1.25;
  color: #ffffff;
}

li::marker {
  color: #ffffff;
}

a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

a:hover {
  color: #00ff00;
}

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 330px 300px;
  margin: 20px auto;
}

.container .box {
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  text-align: center;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}
